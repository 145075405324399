<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2 pt-6">
        <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
        <v-card class="my-12 pt-10 pb-8 px-10">
          <div class="d-flex justify-center mb-9" style="font-size: 20px; font-weight: bold">
            <span>備考を変更する</span>
          </div>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg of apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-row align="center">
            <v-col cols="12" md="1">
              <div>
                <span>備考</span>
              </div>
            </v-col>
            <v-col cols="12" md="11">
              <v-textarea
                v-model="$v.Form.mbr_nickname.$model"
                hide-details="auto"
                color="#ff6e40"
                outlined
                :error-messages="nicknameErrors"
                dense
                rows="1"
                placeholder="ご備考を自由にご記入ください"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-9">
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3" class="d-flex justify-end">
              <v-btn outlined width="100%" depressed @click="back">
                <span>取消</span>
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3" class="d-flex justify-end">
              <v-btn color="#ff6e40" depressed width="100%" @click="submit(Form)">
                <span style="color: #fff">変更</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { maxLength } from 'vuelidate/lib/validators'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    Form: {
      old_nickname: '',
      mbr_nickname: '',
    },
  }),
  validations: {
    Form: {
      mbr_nickname: {
        maxLength: maxLength(45),
      },
    },
  },
  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('auth', ['info']),
    nicknameErrors() {
      const errors = []
      if (!this.$v.Form.mbr_nickname.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_nickname.maxLength && errors.push('最大45文字までです!')

      return errors
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setAbsoluteOverlayStatus']),
    ...mapActions('auth', ['ChangeDescription']),
    ...mapActions('auth', ['loadInfo']),

    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadInfo()
        .then(() => {
          this.Form.old_nickname = this.info.mbr_nickname
        })
        .finally(() => {
          this.setAbsoluteOverlayStatus(false)
        })
    },

    submit(Form) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (Form.old_nickname === Form.mbr_nickname) {
          this.back()
        } else {
          this.setAbsoluteOverlayStatus(true)
          this.ChangeDescription(Form)
            .then(() => {
              this.setAbsoluteOverlayStatus(false)
              this.back()
            })
            .catch(error => {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
              this.setAbsoluteOverlayStatus(false)
            })
        }
      }
    },
    back() {
      this.$router.push('/account')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
